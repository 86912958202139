$ruler: 1rem;
$color-red: #ae1100;
$color-blue: #285cdf;
$color-bg: #ebecf0;
$color-shadow: #babecc;
$color-white: #fff;

.contain{
    height: 100vh;
    width: 100vw;
    background-color: $color-bg;
}

h1 {
    margin: 0;
}

p,
input,
select,
textarea,
button {
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.2px;
    font-size: $ruler;
}

div,
p {
    color: $color-shadow;
    text-shadow: 0.1rem 0.1rem 0.1rem $color-white;
}

form {
    padding: 2rem;
    margin: 0 auto;
}

.segment {
    padding: 4rem 0;
    text-align: center;
}

button,
input {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: 20rem;
    padding: 1rem;
    background-color: $color-bg;
    text-shadow: 0.1rem 0.1rem 0 $color-white;
}

label {
    display: block;
    margin: 4rem auto;
    width: 31.25rem; /* 500px in rem */
}

input {
    margin-right: 0.5rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem $color-shadow, inset -0.5rem -0.5rem 1rem $color-white;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    appearance: none;
    -webkit-appearance: none;

    &:focus {
        box-shadow: inset 0.1rem 0.1rem 0.2rem $color-shadow,
            inset -0.1rem -0.1rem 0.2rem $color-white;
    }
}

button {
    color: #61677c;
    font-weight: bold;
    box-shadow: -0.5rem -0.5rem 2rem $color-white, 0.5rem 0.5rem 2rem $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        box-shadow: -0.2rem -0.2rem 0.5rem $color-white, 0.2rem 0.2rem 0.5rem $color-shadow;
    }

    &:active {
        box-shadow: inset 0.1rem 0.1rem 0.2rem $color-shadow,
            inset -0.1rem -0.1rem 0.2rem $color-white;
    }

    .icon {
        margin-right: 0.5rem;
    }

    &.unit {
        border-radius: 0.5rem;
        line-height: 0;
        width: 3rem;
        height: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.5rem;
        font-size: 1.2rem;

        .icon {
            margin-right: 0;
        }
    }

    &.red {
        display: block;
        width: 31.25rem; /* 500px in rem */
        margin: 4rem auto;
        color: $color-red;
    }

    &.blue {
        display: block;
        width: 31.25rem; /* 500px in rem */
        margin: 4rem auto;
        color: $color-blue;
    }
}

.bottoms{
    position: relative;
    width: 20rem; /* 80px in rem */
    height: 30rem; /* 120px in rem */
    background: radial-gradient(circle at 15rem 10rem,
            #000,
            #000 0.875rem,
            transparent 0.875rem),
        radial-gradient(circle at 10rem 10rem, #000, #000 0.875rem, transparent 0.875rem),
        radial-gradient(circle at 12.5rem 12.5rem, #fff, #fff);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 25vw;
    margin-top: 8vh;
    border-radius: 20rem 20rem 30rem 10rem;
    transform: skewX(-10deg);
    animation: moveMain 6.2s infinite ease-out alternate;
}

.bottoms::before,
.bottoms::after {
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 50%;
}

.bottoms::before {
    width: 1.25rem; /* 20px in rem */
    height: 1.25rem; /* 20px in rem */
    top: 5rem; /* 50px in rem */
    left: -0.625rem; /* -10px in rem */
    box-shadow: 0 0 0 0.0625rem #fff;
    animation: move 1.2s infinite ease-in;
}

.bottoms::after {
    width: 1.875rem; /* 30px in rem */
    height: 1.5625rem; /* 25px in rem */
    top: 9.375rem; /* 95px in rem */
    left: -0.625rem; /* -10px in rem */
    box-shadow: 0 0 0 0.0625rem #fff;
    animation: move2 1.2s infinite ease-in;
}

@keyframes move {

    80%,
    100% {
        box-shadow: -3.75rem 0 0 0.03125rem rgba(255, 255, 255, 0.5);

    }
}

@keyframes move2 {

    80%,
    100% {
        box-shadow: -6.25rem 0 0 0.03125rem rgba(255, 255, 255, 0.2);

    }
}

@keyframes moveMain {
    42% {
        transform: skewX(-10deg) translate(10rem, 0) translateX(0);
    }
    100% {
        transform: skewX(-10deg) translate(10rem, 0) translateX(25vw);
    }
}
