$ruler: 16px;
$color-red: #ae1100;
$color-blue: #285cdf;
$color-bg: #ebecf0;
$color-shadow: #babecc;
$color-white: #fff;

.contain {
    height: 100vh;
    width: 100vw;
    background-color: $color-bg;
}

h1 {
    margin: 0;
}


p,
input,
select,
textarea,
button {
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.2px;
    font-size: $ruler;
}

div,
p {
    color: $color-shadow;
    text-shadow: 1px 1px 1px $color-white;
}

form {
    padding: $ruler;
    margin: 0 auto;
}

.segment {
    padding: $ruler * 2 0;
    text-align: center;
}

button,
input {
    border: 0;
    outline: 0;
    font-size: $ruler;
    border-radius: $ruler * 20;
    padding: $ruler;
    background-color: $color-bg;
    text-shadow: 1px 1px 0 $color-white;
}

label {
    display: block;
    margin: $ruler * 2.0 auto;
    width: 500px;
}

input {
    margin-right: $ruler/2;
    box-shadow: inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-white;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    appearance: none;
    -webkit-appearance: none;

    &:focus {
        box-shadow: inset 1px 1px 2px $color-shadow,
            inset -1px -1px 2px $color-white;
    }
}

button {
    color: #61677c;
    font-weight: bold;
    box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
    }

    &:active {
        box-shadow: inset 1px 1px 2px $color-shadow,
            inset -1px -1px 2px $color-white;
    }

    .icon {
        margin-right: $ruler/2;
    }

    &.unit {
        border-radius: $ruler/2;
        line-height: 0;
        width: $ruler * 3;
        height: $ruler * 3;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 $ruler/2;
        font-size: $ruler * 1.2;

        .icon {
            margin-right: 0;
        }
    }

    &.red {
        display: block;
        width: 500px;
        margin: $ruler * 2.0 auto;
        color: $color-red;
    }

    &.blue {
        display: block;
        width: 500px;
        margin: $ruler * 2.0 auto;
        color: $color-blue;
    }
}


.bottoms {
    position: relative;
    width: 80px;
    height: 120px;
    background: radial-gradient(circle at 60px 40px,
            #000,
            #000 7px,
            transparent 7px),
        radial-gradient(circle at 40px 40px, #000, #000 7px, transparent 7px),
        radial-gradient(circle at 50px 50px, #fff, #fff);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 25vw;
    margin-top: 8vh;
    border-radius: 40px 40px 60px 20px;
    transform: skewX(-10deg);
    animation: moveMain 6.2s infinite ease-out alternate;
}

.bottoms::before,
.bottoms::after {
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 50%;
}

.bottoms::before {
    width: 20px;
    height: 20px;
    top: 50px;
    left: -10px;
    box-shadow: 0 0 0 1px #fff;
    animation: move 1.2s infinite ease-in;
}

.bottoms::after {
    width: 30px;
    height: 25px;
    top: 95px;
    left: -10px;
    box-shadow: 0 0 0 1px #fff;
    animation: move2 1.2s infinite ease-in;
}

@keyframes move {

    80%,
    100% {
        box-shadow: -60px 0 0 0.5px rgba(255, 255, 255, 0.5);

    }
}

@keyframes move2 {

    80%,
    100% {
        box-shadow: -100px 0 0 0.5px rgba(255, 255, 255, 0.2);

    }
}

@keyframes moveMain {
    42% {
        transform: skewX(-10deg) translate(40px, 0) translateX(0);
    }

    100% {
        transform: skewX(-10deg) translate(40px, 0) translateX(50vw);
    }
}